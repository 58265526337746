/* eslint-disable no-return-assign */
import React, { Component } from 'react'

import SignInForm, { SignInFacebook } from '../components/SignIn'

class SignInPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'facebook',
    }
  }

  render() {
    const { type } = this.state
    return (
      <>
        <section className="mt6 tc ph2 w-100">
          <h2 className="mt0 fw4 mb4">Acesse sua Conta no Immortalis</h2>
          {type === 'facebook' && (
            <div>
              <SignInFacebook onSignIn={() => (window.location.href = '/my-account')} />
              <button
                type="button"
                onClick={() => this.setState({ type: 'email' })}
                className="dib center mt4 dark-gray f6 bn bg-transparent pointer"
              >
                ou acesse com seu e-mail
              </button>
            </div>
          )}
          {type === 'email' && (
            <div>
              <SignInForm onSignIn={() => (window.location.href = '/my-account')} />
              <button
                type="button"
                onClick={() => this.setState({ type: 'facebook' })}
                className="dib center mt4 dark-gray f6 bn bg-transparent pointer"
              >
                ou acesse com sua conta no Facebook
              </button>
            </div>
          )}
        </section>
      </>
    )
  }
}

export default SignInPage
